<template>
  <a-modal
    centered
    v-model:visible="show"
    :title="null"
    width="100%"
    wrap-class-name="full-modal"
    :footer="null"
    :bodyStyle="{ padding: 0 }"
    id="mv-confusion-matrix-modal"
  >
    <template #closeIcon>
      <close-outlined id="confusion-matrix-close-btn" />
    </template>
    <HeatMap :options="heatMapConfig" />
  </a-modal>

  <a-table
    bordered
    :data-source="modelVersionsList.filter((mv) => !mv.is_deleted)"
    :columns="columns"
    :scroll="{
      y: '55vh',
      x: true,
    }"
    :pagination="{ position: ['bottomCenter'] }"
  >
    <template #title>
      <div>
        <a-typography-title :level="5" class="my-0">
          Task Models: &nbsp;
          <a-tag color="blue" id="models-count">{{
            modelVersionsList?.length
          }}</a-tag>
        </a-typography-title>
      </div>
    </template>
    <template #emptyText>
      <div
        style="height: 40vh"
        class="d-flex flex-column align-items-center justify-content-center"
        id="no-models-found"
      >
        <laptop-outlined style="fontsize: 40px" />
        <span class="mt-2">No Models Found</span>
      </div>
    </template>

    <template #bodyCell="{ column, text, record, index }">
      <template v-if="column.dataIndex === 'description'">
        <div class="editable-cell">
          <div
            v-if="editableData[record.id]"
            class="editable-cell-input-wrapper"
          >
            <a-input-group class="description-edit-input">
              <a-textarea
                v-model:value="editableData[record.id].description"
                allowClear
              />
              <a-button
                @click="cancelEdit(record.id)"
                style="width:44px;height:54px"
              >
                <template #icon>
                  <close-circle-outlined />
                </template>
              </a-button>
            </a-input-group>
          </div>
          <div v-else>
            {{ text || ' ' }}
          </div>
        </div>
      </template>

      <template v-if="column.dataIndex === 'date'">
        {{ text ? dateHelper.formatDate(text) : '-' }}
      </template>

      <template v-if="column.dataIndex === 'action'">
        <a-button
          @click="saveRecord(record.id)"
          v-if="editableData[record.id]"
          :loading="isUpdatingModelVersion"
          :disabled="!editableData[record.id].description"
          type="primary"
        >
          <template #icon>
            <check-outlined />
          </template>
          Save
        </a-button>
        <div v-else>
          <a-button
            type="primary"
            class="editable-cell-icon"
            :disabled="isEmpytyDict(index)"
            @click="openModal(index)"
          >
            Show Eval.
          </a-button>
          <a-button
            type="primary"
            class="editable-cell-icon"
            :disabled="isEditDisabled"
            @click="editRecord(record.id)"
          >
            <template #icon>
              <edit-outlined></edit-outlined>
            </template>
          </a-button>
          <a-popconfirm
            title="Are you sure you want to delete this model version?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="toggleDelete(record)"
            :disabled="selectedModelVersion?.id === record.id"
          >
            <a-button
              danger
              class="editable-cell-icon"
              :disabled="selectedModelVersion?.id === record.id"
            >
              <template #icon>
                <delete-outlined></delete-outlined>
              </template>
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </template>
  </a-table>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import dateHelper from '../../../shared/Helpers/dateHelper';
import {
  CloseCircleOutlined,
  CheckOutlined,
  LaptopOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import { userModelVersionTable as columns } from 'src/config/model-version-config';
import HeatMap from './wandb_chart/Heatmap.vue';
import wandbJson from './wandb_history';
import { heatMap } from '@/config/charts-config.js';

export default {
  components: {
    CloseCircleOutlined,
    CheckOutlined,
    EditOutlined,
    DeleteOutlined,
    LaptopOutlined,
    CloseOutlined,
    HeatMap,
  },
  inject: ['toast'],
  setup() {
    return { columns, dateHelper };
  },

  mounted() {
    if (!this.selectedTask && this.modelVersionsList?.length) {
      this.clearModelVersionsList();
    }
  },

  data() {
    return {
      editableData: {},
      show: false,
      heatMapConfig: {
        ...heatMap,
        confusion_matrix: wandbJson.confusion_matrix,
      },
    };
  },

  computed: {
    ...mapGetters([
      'selectedTask',
      'modelVersionsList',
      'isUpdatingModelVersion',
      'taskAssociatedModelVersion',
    ]),

    isEditDisabled() {
      return Object.keys(this.editableData).length !== 0;
    },

    selectedModelVersion() {
      if (!this.taskAssociatedModelVersion) return;
      return this.modelVersionsList.find(
        (model) => model.id === this.taskAssociatedModelVersion
      );
    },
  },

  methods: {
    ...mapActions([
      'updateModelVersion',
      'toggleDelete',
      'clearModelVersionsList',
    ]),

    editRecord(recordId) {
      const editRecord = this.modelVersionsList.filter(
        (item) => item.id === recordId
      )[0];
      this.editableData = {
        ...this.editableData,
        [recordId]: { ...editRecord },
      };
    },

    async saveRecord(recordId) {
      const editedRecord = this.editableData[recordId];
      const data = {
        modelVersionId: editedRecord.id,
        payload: {
          description: editedRecord.description,
        },
      };

      const res = await this.updateModelVersion(data);
      if (!res) {
        this.toast.error('Error occurred in updating model verison!');
      }
      delete this.editableData[recordId];
    },

    cancelEdit(recordId) {
      delete this.editableData[recordId];
    },

    isEmpytyDict(index) {
      const dict = this.modelVersionsList[index].wandb_history;
      return dict === null || Object.keys(dict).length === 0;
    },

    openModal(index) {
      const wandb_history = this.modelVersionsList[index]?.wandb_history;
      if (!wandb_history) return;
      const cols = wandbJson.confusion_matrix[0].length;
      this.heatMapConfig['labels'] = Array.from(
        { length: cols },
        (_, i) => `Class${i + 1}`
      );
      this.show = true;
    },
  },
};
</script>
<style>
.editable-cell-text-wrapper,
.editable-cell-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.description-edit-input {
  width: 90%;
  display: flex;
}

.editable-cell-icon {
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
