<template>
  <a-row :gutter="[8, 8]" class="py-4 px-2 p-md-4 mb-4">
    <a-col :xs="24" :sm="24" :md="8" :lg="8">
      <a-select
        v-model:value="task_id"
        show-search
        ref="model_version_task_select"
        placeholder="Selec Task"
        class="w-100"
        :options="taskOptions"
        @change="$refs.model_version_task_select.blur()"
        :filter-option="true"
        optionFilterProp="label"
        :loading="isFetchingTasks"
        id="model-version-select-task"
      >
      </a-select>
    </a-col>
    <a-col span="24" class="mt-4">
      <model-version-table></model-version-table>
    </a-col>
  </a-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getSortedTask } from '@//utils/task';
import ModelVersionTable from './ModelVersionTable.vue';

export default {
  components: {
    ModelVersionTable,
  },
  inject: ['toast'],
  data() {
    return {
      task_id: null,
    };
  },

  created() {
    this.getAllTasks();
    this.populateParams();
    if (this.selectedTask) {
      this.task_id = this.selectedTask;
    }
  },

  unmounted() {
    this.clearTaskDetails();
  },

  computed: {
    ...mapGetters([
      'selectedTask',
      'allTasks',
      'isFetchingTasks',
      'organization',
    ]),
    taskOptions() {
      return getSortedTask(this.allTasks);
    },
  },

  watch: {
    task_id(value) {
      if (!value) return;
      this.setTaskId(value);
      this.fetchTaskDetails(value);
      this.fetchModelVersionsList(value);
      this.setRouteQueryParams({ taskId: value });
    },
  },
  methods: {
    ...mapActions([
      'getAllTasks',
      'setTaskId',
      'fetchTaskDetails',
      'fetchModelVersionsList',
      'clearTaskDetails',
    ]),

    setRouteQueryParams(query) {
      this.$router.replace({
        name: this.$route.name,
        query,
      });
    },

    populateParams() {
      const { taskId } = this.$route.query;
      this.task_id = taskId && Number(taskId);
    },
  },
};
</script>
